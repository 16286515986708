<template>
	<div class="mainTem">
		<!-- <div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_ee62c49d9dae1b96')}}</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div> -->

		<el-card style="margin-bottom: 10px;">
			<div slot="header" class=""><span>{{$t('i18nn_f87d23ab43f0b299')}}</span></div>
			<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
				
				<div class="filterCon" style="">
					<div class="filterAction">
						<ul class="filterConList">
							<!-- <li>
								<span class="">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
								<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="small"></SelAgentUser>
							</li> -->
							<li>
								<span class="">{{$t('i18nn_c944a6686d996ab3')}}</span>
								<span>{{filterData.whNo}}</span>
								<!-- <whNoSelect @changeData="changWhNo" size="small"></whNoSelect> -->
							</li>
							<li>
								<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
								<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
									maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;">
								</el-input>
							</li>
							<!-- <li>
								<span class="">{{$t('i18nn_c944a6686d996ab3')}}</span>
								<whNoSelect ref="whNoSelect" :disabled="!!whNo" @changeData="changWhNo" size="small"></whNoSelect>
							</li> -->
							<li>
								<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
							</li>
						</ul>
					</div>
				</div>

				<el-table border :data="tableData" @selection-change="handleSelectionChange" size="small" :max-height="$store.state.frameConHeightWh4" style=" width: 100%;">
					
					<el-table-column type="selection" fixed="left" align="center" width="55"></el-table-column>
					
					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
						:label="$t('Storage.tableColumn.no')"></el-table-column>

					<!-- <el-table-column prop="statusName" :label="$t('i18nn_6cdece641436d7ab')" width="">
					</el-table-column> -->
					<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
						<template slot-scope="scope">
							<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="success" v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="warning" v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="danger" v-else-if="'30' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="" v-else-if="'40' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="warning" effect="plain" v-else-if="'50' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						</template>
					</el-table-column>
					
					<el-table-column prop="inWhCode" :label="$t('i18nn_35ec768075eb8f4e')" width="">
					</el-table-column>
					<el-table-column prop="relationNo" :label="$t('i18nn_158db9252e21d1a6')" width="">
					</el-table-column>
					<el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
					</el-table-column>
					<!-- <el-table-column prop="ctnCount" :label="$t('i18nn_bdc361ba04506136')" width="">
					</el-table-column>
					<el-table-column prop="inWhPallte" :label="$t('i18nn_c8773d8d74202801')" width="">
					</el-table-column> -->
					<!-- 在库数（stock）   锁定数（lockTotal）   可用数（stock-lockTotal） -->
					
					<el-table-column prop="stock" :label="$t('i18nn_48bc6e8b56cba243')" width="">
					</el-table-column>
					<el-table-column prop="lockTotal" :label="$t('i18nn_0ddc3b0b28d12b29')" width="">
					</el-table-column>
					<el-table-column prop="stockLockTotal" :label="$t('i18nn_51c7caf81e915eb4')" width="">
						<template slot-scope="scope">
							<strong class="red" v-if="scope.row.lockTotal && scope.row.stock">{{parseFloat(scope.row.stock?scope.row.stock:0)-parseFloat(scope.row.lockTotal?scope.row.lockTotal:0)}}</strong>
						</template>
					</el-table-column>
					
					<!-- <el-table-column prop="inWhTypeName" :label="$t('i18nn_643e08ba53f72f39')" width="">
					</el-table-column> -->
					

					<!-- <el-table-column prop="ctnUnit" :label="$t('i18nn_b6bf0a07fe26f74f')" width="">
					</el-table-column> -->

					<!-- <el-table-column prop="ctnL" width="">
						<template slot="header">
							<div><span>{{$t('i18nn_53f9f6f694a5b0a8')}}</span>(L*W*H)</div>
						</template>
						<template slot-scope="scope">
							{{scope.row.ctnL}}*{{scope.row.ctnW}}*{{scope.row.ctnH}}
						</template>
					</el-table-column>

					<el-table-column prop="ctnWeight" :label="$t('i18nn_9e168db87b716866')" width="">
					</el-table-column> -->
					
					<el-table-column prop="goodsSku" width="">
						<template slot="header">
							<div><span>{{$t('i18nn_c5ef0b068e92e23d')}}</span>SKU</div>
						</template>
					</el-table-column>
					
					<!-- <el-table-column prop="ctnSkuCount" :label="$t('i18nn_85a10d3e5ea0e103')" width="">
					</el-table-column> -->
					
					<el-table-column prop="skuTotal" :label="$t('i18nn_bad53577db0da2d3')" width="">
					</el-table-column>
					<!-- <el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')" width="">
					</el-table-column> -->
					

					<!-- <el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')" width="" show-overflow-tooltip>
					</el-table-column> -->

				</el-table>

				<div class="tableCon_pagination">
					<hy-page-pagination :pagination='pagination' v-on:updatePageData="getPageData"></hy-page-pagination>
				</div>
			</div>
		</el-card>

	</div>
</template>
<script>
	// import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	export default {
		components: {
			// whNoSelect
		},
		props: {
			openTime:{},
			whNo:{}
			// sendId: {
			// 	default: function() {
			// 		return ''
			// 	},
			// 	type: String
			// },
		},
		data() {
			return {

				// UserInfo: this.$store.getters.getUserInfo,
				dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				loading: false,
				
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				
				multipleSelection:[],
				// selectOption: {
				//   wh_weight_unit: [],
				//   wh_vol_unit: [],
				//   wh_goods_fee_type:[],
				//   statusList: [
				//     {
				//       value: '',
				//       label: this.$t('i18nn_16853bda54120bf1')
				//     },
				//     {
				//       value: '1',
				//       label: this.$t('i18nn_fdd8426d7b8869c5')
				//     },
				//     {
				//       value: '2',
				//       label: this.$t('i18nn_619d7e563f879811')
				//     },
				//     {
				//       value: '3',
				//       label: this.$t('i18nn_1dfb3a065d3ffe39')
				//     }
				//   ]
				// },
				//查询，排序方式
				filterData: {
					"orderBy": "id_", //排序字段
					"sortAsc": "desc", //desc降序，asc升序
					
					keyword:'',
					whNo:"",
					// "accountPeriod":"",
					// packId: "",
					// "billNo":""
					// status: '',
					// putWhNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// declareNameCh: '',
				}
			}
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				// if (newVal) {
				// console.log('watch openDateTime HyUpLoadImg.vue');
				this.initData();
				// }
			}
		},
		//创建时
		created() {
		},
		//编译挂载前
		mounted() {
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				this.tableData = [];
				this.filterData.whNo = this.whNo;
				// this.$nextTick(()=>{
				// 	this.$refs.whNoSelect.init(this.filterData.whNo);
				// })
				this.getPageData();
			},
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.filterData.whNo = data.code;
			// 	this.initData();
			// },
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			//获取已经选择的数据
			getSelData(){
				return this.multipleSelection;
			},
			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;
				this.$http.put(this.$urlConfig.WhTransferPlanSelPageList, {
						// "sortAsc": this.filterData.sortAsc,
						// "orderBy": this.filterData.orderBy,
						"offset": (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						"limit": this.pagination.page_size, //当前页显示数目
						
						// thanGtStock:1,
						whNo: this.filterData.whNo?this.filterData.whNo:null,
						// sendId: this.sendId ? this.sendId : null,
						keyword: this.filterData.keyword ? this.filterData.keyword : null,
						// statusList:['20','35'],//已接收，部分转运
						// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
						// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
						// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
						// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
					})
					.then(({
						data
					}) => {

						console.log("分页，请求成功");
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
					})
					.catch((error) => {
						console.log(error);
						console.log("分页，请求失败");
						this.loading_load = false;
					});
			},
			

		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	
</style>
