<template>
	<div class="mainTem">
		<div class="tableConTop" style="position: sticky;top:0;z-index: 10;">
			<el-row>
				<el-col :span="18" class="tableConTopLeft">
					<h3>
						<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">
							{{$t('i18nn_ca5a01a5adf20fe7')}}
						</el-button>

						<!-- <span class="tct_tit">{{editId?$t('i18nn_95fb447f1661affd'):$t('i18nn_bab35eeb688f9eae')}}</span> -->
						<span class="tct_tit" v-if="editId">{{$t('i18nn_dae2f81921b3b3c0')}}</span>
						<span class="tct_tit" v-else>{{$t('i18nn_d992d16df3748947')}}</span>
						<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
						<span class="tct_tit" v-if="form.outWhCode" style="color:#409EFF;">
							<!-- <el-tag> -->
							<span>
								{{$t('i18nn_b1131a0cc3945b5f')}}
							</span>:
							<span>
								{{form.outWhCode}}
							</span>
							<!-- </el-tag> -->
						</span>
					</h3>
				</el-col>
				<el-col :span="6" class="tableConTopRig">
					<div v-if="!isOnlyView">
						<el-button type="success" icon="el-icon-plus" @click="AddBlockData()"
							v-if="!editId">{{$t('i18nn_24c8f46012a25c89')}}</el-button>

						<el-button type="primary" @click="submitFormAction()"
							icon="el-icon-tickets">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
					</div>


					<!-- <div v-if="0==stepActive">
							<el-button type="primary" @click="nextStep">{{$t('i18nn_4639f3a6e07c00b3')}}</el-button>
						</div>
						<div v-else-if="1==stepActive">
							<el-button @click="preStep">{{$t('i18nn_70e058bc23d52c1e')}}</el-button>
							<el-button type="warning" @click="submitFormAction()" style=""
								v-if="!isOnlyView">{{$t('i18nn_e186babc2e9cadf4')}}
							</el-button>
						</div> -->
				</el-col>
			</el-row>

		</div>



		<el-form ref="form" :disabled="isOnlyView" :rules="formRules" :model="form" inline label-width="100px"
			style="width: 100%;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">


			<el-card class="hover-highlight-red" :shadow="'never'" v-for="(item,index) in form.recordList" :key="index" style="margin:10px;">
				<div slot="header">
					<div style="display: flex;justify-content: space-between;">
						<strong><span>{{$t('i18nn_59550e3d8c8088f9')}}</span>{{index+1}}</strong>
						<div v-if="!editId">
							<el-button type="warning" icon="el-icon-minus"
								@click="DelBlockData($event,index)">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
						</div>
					</div>
				</div>

				<div>

					<el-form-item :label="$t('i18nn_c944a6686d996ab3')" prop="" required>
						<!-- <el-input type="text" clearable v-model="item.whNo" :placeholder="$t('FormMsg.Please_select')"></el-input> -->
						<el-select filterable clearable size="" v-model="item.whNo" :placeholder="$t('FormMsg.Please_select')">
							<el-option v-for="item in selectOption.whNo" :key="item.code" :label="$Utils.i18nCodeText(item)"
								:value="item.code"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item :label="$t('i18nn_e1e2b21c7b4b951f')" prop="" required>
						<!-- <el-input type="text" clearable v-model="item.trWay" :placeholder="$t('i18nn_c6bf739e696c2bfc')"></el-input> -->
						<el-select filterable clearable size="" v-model="item.trWay" :placeholder="$t('FormMsg.Please_select')" @change="trWayChange($event,index)">
							<el-option v-for="item in selectOption.wh_car_tms_type" :key="item.code"
								:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</el-form-item>

					
					<!-- 只有卡派需要选择地址，自提和快递不需要选择地址 -->
					<div v-if="'2'==item.trWay">
						<el-form-item :label="$t('i18nn_0bbce74881c893df')" prop="" required>
							<!-- <el-input type="text" clearable v-model="item.addrType"
								:placeholder="$t('i18nn_c6bf739e696c2bfc')"></el-input> -->
							<el-select filterable clearable size="" v-model="item.addrType" :placeholder="$t('FormMsg.Please_select')"
								@change="clearAddrCode($event,index)">
								<el-option v-for="item in selectOption.biz_wh_tr_plan_addr_type" :key="item.code"
									:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item :label="$t('i18nn_5cd591b578350972')" prop="" required>
							<span v-if="'0'==item.addrType">
								<el-input type="text" disabled v-model="item.trAddrCode" :placeholder="$t('FormMsg.Please_select')"
									style="width: 150px;"></el-input>
								<el-button type="primary" size="small" @click="openSelAddr(index)"
									icon="el-icon-magic-stick">{{$t('i18nn_7fb6ada66a1fccca')}}</el-button>
								<span>
									{{item.trAddrInfo}}
								</span>
						
							</span>
							<span v-else>
								<el-input type="text" clearable v-model="item.trAddrCode"
									:placeholder="$t('i18nn_5a9aefbc03c778f7')"></el-input>
							</span>
						
						
						</el-form-item>
					</div>
					
					<!-- 自提：自提单号
					快递-渠道商：Fedex，UPS -->
					<el-form-item :label="$t('i18nn_7f0e36c0c32152b9')" prop="" required v-if="'1'==item.trWay">
						<el-input type="text" clearable v-model="item.trAddrCode"
							:placeholder="''"></el-input>
					</el-form-item>
					
					<el-form-item :label="$t('i18nn_316e16926411681a')" prop="" required v-if="'3'==item.trWay">
						<el-input type="text" clearable v-model="item.trAddrCode"
							:placeholder="'Fedex,UPS...'"></el-input>
					</el-form-item>
					
					<!-- <div> -->
						<el-form-item :label="$t('i18nn_886ea69af099215d')" prop="">
							<el-input-number size="" v-model="item.pallteCount" controls-position="right">
							</el-input-number>
						</el-form-item>
						<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop="">
							<el-input type="textarea" :rows="3" placeholder="" v-model="item.remark" :maxlength="2000" show-word-limit style="width: 500px;">
							</el-input>
						</el-form-item>
					<!-- </div> -->


					<!-- <el-card :shadow="'never'"> -->
					<!-- <div slot="header">
						<div>{{$t('i18nn_e6bdd31025bb4f9f')}}</div>
					</div> -->

					<!-- <div> -->

					<!-- "ctnMark":"外箱标识",
							                "quantity":"数量",
							                "trRecordId":"入库明细ID",
							                "fbaNo":"FBA编号",
					                "fbaPre":"FBA PRE#" -->
					<div>
						<el-button type="success" size="small" icon="el-icon-plus"
							@click="addDet(index)">{{$t('i18nn_4f5f83fc6780a29c')}}</el-button>
					</div>


					<el-table border :data="item.planList" size="small" :max-height="$store.state.frameConHeightWh9"
						style="width: 100%;">
						<el-table-column type="index" fixed="left" width="50" align="center"
							:label="$t('Storage.tableColumn.no')"></el-table-column>

						<el-table-column prop="inWhCode" :label="$t('i18nn_35ec768075eb8f4e')">
						</el-table-column>

						<el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
							<template slot-scope="scope">
								<div>
									<!-- <el-input size="small" v-model="scope.row.ctnMark">
								</el-input> -->
									{{scope.row.ctnMark}}
								</div>
							</template>
						</el-table-column>

						<el-table-column prop="quantity" :label="$t('i18nn_f9db93b87e08763b')" width="">
							<template slot-scope="scope">
								<div>
									<el-input-number size="small" v-model="scope.row.quantity" controls-position="right">
									</el-input-number>
								</div>
							</template>
						</el-table-column>

						<el-table-column prop="fbaNo" :label="'FBA编号'" width="">
							<template slot-scope="scope">
								<div>
									<el-input size="small" v-model="scope.row.fbaNo">
									</el-input>
								</div>
							</template>
						</el-table-column>

						<el-table-column prop="fbaPre" :label="'FBA PRE#'" width="">
							<template slot-scope="scope">
								<div>
									<el-input size="small" v-model="scope.row.fbaPre">
									</el-input>
								</div>
							</template>
						</el-table-column>
						
						<!-- <el-table-column prop="trRecordId" :label="'入库ID'">
						</el-table-column> -->

						<el-table-column :label="$t('Storage.tableColumn.operation')" width="120px" fixed="right" align="left"
							v-if="!isOnlyView">
							<template slot-scope="scope">
								<div>
									<el-button @click="delDet($event, index, scope.$index)" type="danger" size="small"
										icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
					<!-- </div> -->
					<!-- </el-card> -->


					<el-card :shadow="'never'">
						<div slot="header">
							<div>
								<strong>{{$t('i18nn_f5d43732e3f6cf4d')}}</strong>
							</div>
						</div>

						<div v-loading="loadingUploadFile">
							<!-- <el-button @click="openFile(index)"></el-button> -->

							<el-button type="primary" icon="el-icon-paperclip"
								@click="openAddFile(index)">{{$t('i18nn_ad36057ff6a7ce6b')}}</el-button>

							<ul>
								<li v-for="(item2,index2) in item.attachments" :key="index2">
									<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999" :fit="'contain'"
										:src="item2.url" :preview-src-list="item.attachments.map(itemPre=> { return itemPre.url})">
										<div slot="error" class="image-slot">
											<i class="el-icon-document"></i>
										</div>
									</el-image>
									<a :href="item2.url" :title="item2.url" target="_blank">{{ item2.fileName }}</a>

									<!-- <el-button @click="delFile($event,index, index2)" type="text" size="mini" icon="el-icon-delete"
										style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button> -->
									
									<el-button @click="delFileAction($event, item2,index, index2)" type="text" size="mini"
										icon="el-icon-delete" style="padding: 0 10px;" v-if="item2.id">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
									<el-button @click="delFile($event, index, index2)" type="text" size="mini" icon="el-icon-delete"
										style="padding: 0 10px;" v-else>{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
										
								</li>
							</ul>
						</div>
					</el-card>

				</div>
			</el-card>







		</el-form>

		<!-- <div class="submit-footer" style="text-align: center;"> -->
		<!-- <div v-if="0==stepActive">
				<el-button type="primary" @click="nextStep">{{$t('i18nn_4639f3a6e07c00b3')}}</el-button>
			</div>
			<div v-else-if="1==stepActive">
				<el-button @click="preStep">{{$t('i18nn_70e058bc23d52c1e')}}</el-button>
				<el-button type="primary" @click="nextStep">{{$t('i18nn_4639f3a6e07c00b3')}}</el-button>
			</div>
			<div v-else-if="2==stepActive">
				<el-button @click="preStep">{{$t('i18nn_70e058bc23d52c1e')}}</el-button>
				<el-button type="warning" @click="submitFormAction()" style="width: 200px;"
					v-if="!isOnlyView">{{$t('i18nn_e186babc2e9cadf4')}}
				</el-button>
			</div> -->
		<!-- <el-button type="warning" @click="submitFormAction()" style="" v-if="!isOnlyView">{{$t('i18nn_e186babc2e9cadf4')}}
			</el-button> -->
		<!-- </div> -->



		<!-- 要运送的货物 -->
		<div style="" v-if="!isOnlyView">
			<el-drawer :wrapperClosable="false" :title="$t('i18nn_2ad108ab2c560530')" :append-to-body="true" :size="'60%'"
				:visible.sync="drawer" :direction="'rtl'">
				<div>

					<!-- <div style="padding:10px;">
						<span>{{$t('i18nn_9d3249981866af60')}}</span>：
						<el-radio-group v-model="form.trStockSour" @change="changeTrStockSour">
							<el-radio v-for="item in selectOption.wh_tr_stock_source" :key="item.code"
								:label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio>
						</el-radio-group>
					</div> -->
					<!-- <div v-if="'10'==form.trStockSour"> -->
					<TransportPlanOutWhSelPage ref="TransportInWhDetPageList" :openTime="TransportInWhDetOpenTime"
						:whNo="TransportInWhDetWhNo">
					</TransportPlanOutWhSelPage>
					<div style="margin: 10px;">
						<el-button icon="el-icon-check" type="warning" @click="addRec()">
							{{$t('i18nn_760f75dc397a9cb8')}}</el-button>
					</div>
					<!-- </div> -->

					<!-- <div v-else-if="'20'==form.trStockSour">
						<TransportSkuInventoriesPageList :openTime="TransportInWhDetOpenTime"
							:whNo="TransportInWhDetWhNo" ref="TransportSkuInventoriesPageList">
						</TransportSkuInventoriesPageList>
						<div style="margin: 10px;">
							<el-button icon="el-icon-check" type="warning" @click="addRecSkuInv()">
								{{$t('i18nn_760f75dc397a9cb8')}}</el-button>
						</div>
					</div> -->
				</div>

			</el-drawer>

		</div>

		<!--  附件上传 -->
		<!-- <whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess">
		</whFileUpload> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto"
					height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

		<!--选择sku-->
		<!-- <el-dialog :title="$t('FormMsg.Select')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0">
			<div style=""><WSkuInfo :isSel="true" :status="'1'" @selectRow="selWhGoodsData"></WSkuInfo></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSelVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

		<!-- 附件 -->
		<dialogFileUpload ref="dialogFileUpload" :folder="'storage/TransportPlan/'" :fileKey="'TransportPlanFile'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload>

		<!-- 地址 -->
		<TransportAddressListDialog :openTime="TransportAddressOpenTime" @selSuccess="addrSelSuccess">
		</TransportAddressListDialog>
	</div>
</template>
<script>
	import {
		deepClone
	} from '@/utils/common.js';
	import {
		getDicData
	} from '@/axios/common.js';
	// import TransshipmentImport from '@/components/StorageCenter/workOrder/workOrderTem/TransshipmentImport.vue';
	// import WSkuInfo from '@/components/StorageCenter/WSkuProduct/WSkuInfo.vue';
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	// import transferAddressSel from '@/components/StorageCenter/components/transferAddressSel.vue';
	// import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';
	import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';
	// import whFileUploadInner from '@/components/StorageCenter/components/whFileUploadInner.vue';
	// import HotTableTransportInWh from '@/components/StorageCenter/Transport/HotTableTransportInWh.vue';
	// import TransportInWhDetListScroll from '@/components/StorageCenter/Transport/TransportInWhDetListScroll.vue';
	// import TransportSkuInventoriesListScroll from '@/components/StorageCenter/Transport/TransportSkuInventoriesListScroll.vue';

	import TransportAddressListDialog from '@/components/StorageCenter/Transport/TransportAddressListDialog.vue';

	import TransportPlanOutWhSelPage from '@/components/StorageCenter/Transport/TransportPlanOutWhSelPage.vue';

	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		// },

		// props: {
		// 	// mobile:"",
		// 	editId: {
		// 		// default: function() {
		// 		//   return false;
		// 		// },
		// 		// type: Boolean
		// 	},
		// 	openTime: {
		// 		// default: function() {
		// 		//   return '';
		// 		// },
		// 		// type: String
		// 	}
		// },
		components: {
			// WSkuInfo,
			// HyElWhUpLoad,
			// HyQuillEditor,
			// TransshipmentImport
			TransportAddressListDialog,
			TransportPlanOutWhSelPage,
			// TransportInWhDetListScroll,
			// TransportSkuInventoriesListScroll,
			whNoSelect,
			// transferAddressSel,
			// whFileUpload,
			dialogFileUpload
			// HotTableTransportInWh
			// whFileUploadInner
		},
		data() {
			return {
				// stepActive: 0,
				// serKeyword: "",
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改
				// dialogFormMsg: '',
				editId: '',
				//导入excel
				// dialogUploadVisible: false,
				loadingExcel: false,
				fileExcel: '',
				// excelData: [],
				excelFileName: '',

				// fileRelationId: '',
				// FileUploadOpenTime: '',

				TransportOpenIndex: 0,

				TransportInWhDetOpenTime: "",
				TransportInWhDetWhNo: "",

				TransportAddressOpenTime: "",

				drawer: false,

				FileUploadOpenTime: '',

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				//打开SKU选择框
				// dialogSelVisible: false,
				// openRowData: {},
				// openRowIndex: {},

				loading: false,

				loading_det: false,

				// isSaveAddress: false,
				// addressName: "",

				// fbaAddress:{},
				loadingUploadFile: false,
				// isShowSkuCheck: false,
				form: {
					"recordList": [{
						"whNo": null, //this.$t('i18nn_a3b6f85b4a9530ff'),
						"addrType": null, //this.$t('i18nn_0bbce74881c893df'),
						"trAddrCode": null, //this.$t('i18nn_8758fd50c87d6c9c'),
						"trWay": null, //this.$t('i18nn_a29209bd396e4bdd'),
						"attachments": [], //this.$t('i18nn_f5d43732e3f6cf4d')
						pallteCount: 0,
						remark: "",
						"planList": [
							// {
							// 	"ctnMark": null, //this.$t('i18nn_73fc38e67381ae73'),
							// 	"quantity": null, //this.$t('i18nn_f9db93b87e08763b'),
							// 	"trRecordId": null, //"入库明细ID",
							// 	"fbaNo": null, //"FBA编号",
							// 	"fbaPre": null, //"FBA PRE#"
							// },
						]
					}],
				},

				formRules: {
					// sendNo: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					// recevieName: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
				},
				loading_load: false,
				selectOption: {
					whNo: [],
					// biz_wh_tr_plan_way: [],
					wh_car_tms_type:[],
					biz_wh_tr_plan_addr_type: [],
				},

				dialogFormStatus: 0, //0-新增，1-修改

				isOnlyView: false,

			};
		},
		// watch: {
		// 	'$route.query': function(newVal, oldVal) {
		// 		console.log('$route.query', newVal);
		// 		// if(newVal){
		// 		// this.dialogFormVisible = true;
		// 		this.initData();
		// 		// }
		// 	}
		// },
		//创建时
		created() {
			// this.getPageData();
			this.initData();
		},
		//编译挂载前
		mounted() {

			// this.getDicData();
			//数据字典
			getDicData(['biz_wh_tr_plan_addr_type','wh_car_tms_type'],
				(data)=>{
					this.selectOption.biz_wh_tr_plan_addr_type = data['biz_wh_tr_plan_addr_type'];
					this.selectOption.wh_car_tms_type = data['wh_car_tms_type'];
			});
			this.getWhListData();
		},
		methods: {
			initData() {
				// this.$nextTick(() => {
				// 	this.$refs.HotTableTransport.clear();
				// });
				// this.isOnlyView = false;

				if (this.$route.query.editId) {
					this.editId = this.$route.query.editId;
					//不允许编辑
					// this.hasEdit = false;
					this.getPageBaseData(this.editId);
					// this.isOnlyView = true;
					// this.fileRelationId = this.editId;
				} else {
					// this.initTransportInventory();
				}

				// this.openUploadFile();
				// this.FileUploadOpenTime = new Date().getTime();
				// else {
				// 	this.hasEdit = true;
				// }
			},
			
			trWayChange(v,index){
				let item = this.form.recordList[index];
				item.addrType = null;
				item.trAddrCode = null;
				item.trAddrInfo = "";
				this.$set(this.form.recordList, index, item);
				this.$forceUpdate();
			},
			//仓库列表加载成功
			// whLoadSuccess(list) {
			// 	if (!this.$route.query.editId) { //新增时
			// 		this.$nextTick(() => {
			// 			if (list.length > 0) {
			// 				let defVal = "";
			// 				defVal = list[0].code;
			// 				this.$refs.whNoSelect.init(defVal);
			// 				this.form.whNo = defVal;
			// 				this.initTransportInventory();
			// 			}
			// 		});
			// 	}
			// },
			// serTranInv() {
			// 	this.initTransportInventory();
			// },
			// preStep() {
			// 	this.stepActive--;
			// },
			// nextStep() {
			// 	if (0 == this.stepActive) {
			// 		if (!this.form.whNo) {
			// 			this.$message.warning(this.$t('i18nn_f217cfe6c6291a89'));
			// 			return;
			// 		}
			// 		if (this.form.recordList.length <= 0) {
			// 			this.$message.warning(this.$t('i18nn_80755d56db88fca2'));
			// 			return;
			// 		}
			// 	} else if (1 == this.stepActive) {
			// 		// if(!this.form.relationNo){
			// 		// 	this.$message.warning("请填写FBA申请号或物流号");
			// 		// 	return;
			// 		// }
			// 		// if(!this.form.trType){
			// 		// 	this.$message.warning("请选择装运类型");
			// 		// 	return;
			// 		// }
			// 		// if('1'==this.form.isFba && !this.form.fbaAddrCode){
			// 		// 	this.$message.warning("请填写FBA仓库编号");
			// 		// 	return;
			// 		// }
			// 	}
			// 	this.stepActive++;
			// },
			goBack() {
				// this.$router.push({
				// 	name: 'TransportOutWh'
				// });
				this.$router.go(-1);
			},
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.form.whNo = data.code;
			// 	//清空已选择的货物
			// 	this.form.recordList = [];

			// 	this.initTransportInventory();
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// },
			// changeTransferAddress(data) {
			// 	console.log('changeTransferAddress', data);
			// 	// this.form.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// 	this.addressName = data.name;
			// 	this.form.addr = data.address;
			// 	this.form.city = data.city;
			// 	this.form.state = data.state;
			// 	this.form.country = data.country;
			// 	this.form.postalCode = data.zipCode;

			// 	// "name": this.addressName, //this.$t('i18nn_59764ba919e3d70d'),
			// 	// "address": this.form.addr, //this.$t('i18nn_8758fd50c87d6c9c'),
			// 	// "city": this.form.city, //this.$t('i18nn_e05b5d049b64b040'),
			// 	// "state": this.form.state, //this.$t('i18nn_7cab79716236ebb0'),
			// 	// "zipCode": this.form.postalCode, //this.$t('i18nn_c4913ab43009b365'),
			// 	// "country": this.form.country, //"US"
			// },

			//初始化转运库存
			// initTransportInventory() {
			// 	this.TransportInWhDetOpenTime = new Date().getTime();
			// 	this.TransportInWhDetWhNo = this.form.whNo;
			// },
			//初始化sku库存
			// initInStoreInventory(){
			// 	this.TransportInWhDetOpenTime = new Date().getTime();
			// 	this.TransportInWhDetWhNo = this.form.whNo;
			// },
			//转运库存数量变化
			// TransportInvChange(list) {
			// 	//新增
			// 	let HasRepeat = false; //是否存在重复
			// 	let new_recordList = list.filter(item => {
			// 		return item.trCtnCount > 0;
			// 	});
			// 	console.log('new_recordList', new_recordList);
			// 	// let new_recordList_ids = new_recordList.map(item=>{return item.id})
			// 	let old_recordList = deepClone(this.form.recordList);

			// 	new_recordList.map(item => {
			// 		let hasIndex = old_recordList.findIndex(item2 => {
			// 			return item2.inWhRecord == item.id;
			// 		});
			// 		if (hasIndex > -1) { //存在
			// 			let newObj = this.form.recordList[hasIndex];
			// 			newObj.trCtnCount = item.trCtnCount;
			// 			this.$set(this.form.recordList, hasIndex, newObj);
			// 		} else { //不存在
			// 			this.form.recordList.push({
			// 				"ctnMark": item.ctnMark, //this.$t('i18nn_fa4d50bc26e39b26'),
			// 				"inWhCode": item.inWhCode, //this.$t('i18nn_559fed47b0e95407'),
			// 				"inWhRecord": item.id, //"入库明细ID",
			// 				"trCtnCount": item.trCtnCount, //this.$t('i18nn_bdc361ba04506136'),
			// 				"trStockSour": this.form.trStockSour, //货物来源
			// 				"goodsSku": item.goodsSku,
			// 				"remark": null, //备注
			// 				// "remark":item.remark,//备注
			// 				// "trPallte": item.inWhPallte, //this.$t('i18nn_c8773d8d74202801')
			// 			});
			// 		}
			// 	});

			// },

			//sku库存改变
			// SkuInvChange(list) {
			// 	//新增
			// 	let HasRepeat = false; //是否存在重复
			// 	let new_recordList = list.filter(item => {
			// 		return item.trCtnCount > 0;
			// 	});
			// 	console.log('new_recordList', new_recordList);
			// 	// let new_recordList_ids = new_recordList.map(item=>{return item.id})
			// 	let old_recordList = deepClone(this.form.recordList);

			// 	new_recordList.map(item => {
			// 		let hasIndex = old_recordList.findIndex(item2 => {
			// 			return item2.ctnMark == item.goodsSku;
			// 		});
			// 		if (hasIndex > -1) { //存在
			// 			let newObj = this.form.recordList[hasIndex];
			// 			newObj.trCtnCount = item.trCtnCount;
			// 			this.$set(this.form.recordList, hasIndex, newObj);
			// 		} else { //不存在
			// 			this.form.recordList.push({
			// 				"ctnMark": item.goodsSku, //this.$t('i18nn_fa4d50bc26e39b26'),
			// 				"inWhCode": '', //this.$t('i18nn_559fed47b0e95407'),
			// 				"inWhRecord": null, //"入库明细ID",
			// 				"trCtnCount": item.trCtnCount, //this.$t('i18nn_bdc361ba04506136'),
			// 				"trStockSour": this.form.trStockSour, //货物来源
			// 				"goodsSku": item.goodsSku,
			// 				"remark": null, //备注
			// 			});
			// 		}
			// 	});
			// },

			//打开选项抽屉
			openDrawer(index) {
				if (!this.form.recordList[index]) {
					this.$message.warning(this.$t('i18nn_af757a29a5046ebe'));
					return;
				}
				if (!this.form.recordList[index].whNo) {
					this.$message.warning(this.$t('FormMsg.Select_long_out_wh'));
					return;
				}
				// console.log(this.form.recordList[index]);
				// if (!this.form.trStockSour) {
				// 	this.$message.warning(this.$t('FormMsg.Select_long_goods_source'));
				// 	return;
				// }
				// this.form.trStockSour = "10"; //默认转运入库-源
				this.drawer = true;
				//查询对应仓库数据
				this.TransportInWhDetOpenTime = new Date().getTime();
				this.TransportInWhDetWhNo = this.form.recordList[index].whNo;
				this.$forceUpdate();
			},
			// changeTrStockSour(V) {
			// 	//查询对应仓库数据
			// 	// this.TransportInWhDetOpenTime = new Date().getTime();
			// 	// this.TransportInWhDetWhNo = this.form.whNo;
			// 	this.$forceUpdate();
			// },
			handleClick(tab, event) {
				console.log(tab, event);
			},
			//添加的选择的数据-转运的货物
			addRec() {
				this.$nextTick(() => {
					let selData = this.$refs.TransportInWhDetPageList.getSelData();
					console.log("selData", selData);
					if (selData.length <= 0) {
						this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
						return;
					}

					let index = this.TransportOpenIndex;

					let blockData = this.form.recordList[index];

					//新增
					// let HasRepeat = false; //是否存在重复
					// let new_recordList = [];
					// let old_recordList = this.form.recordList;
					// selData.map(item => {
					// 	let hasIndex = old_recordList.findIndex(item2 => {
					// 		return item2.inWhRecord == item.id;
					// 	});
					// 	if (hasIndex > -1) { //存在
					// 		HasRepeat = true;
					// 	} else {
					// 		let trCtnCount = parseFloat(item.stock ? item.stock : 0) - parseFloat(item
					// 			.lockTotal ? item.lockTotal : 0);
					// 		new_recordList.push({
					// 			"ctnMark": item.ctnMark, //this.$t('i18nn_fa4d50bc26e39b26'),
					// 			"inWhCode": item.inWhCode, //this.$t('i18nn_559fed47b0e95407'),
					// 			"inWhRecord": item.id, //"入库明细ID",
					// 			"trCtnCount": trCtnCount, //this.$t('i18nn_bdc361ba04506136'),
					// 			"trStockSour": this.form.trStockSour, //货物来源
					// 			"goodsSku": item.goodsSku,
					// 			"remark": null, //备注
					// 			// "remark":item.remark,//备注
					// 			// "trPallte": item.inWhPallte, //this.$t('i18nn_c8773d8d74202801')
					// 		});
					// 	}
					// });
					// if (HasRepeat) {
					// 	this.$message.success(this.$t('i18nn_a8bcab50b1eccec0'));
					// }


					selData.map(item => {
						// let trCtnCount = parseFloat(item.stock ? item.stock : 0) - parseFloat(item.lockTotal ? item.lockTotal : 0);
						let trCtnCount = null;
						blockData.planList.push({
							"id": null, //"数据ID",
							"inWhCode":item.inWhCode,
							"ctnMark": item.ctnMark, //this.$t('i18nn_73fc38e67381ae73'),
							"quantity": trCtnCount, //this.$t('i18nn_f9db93b87e08763b'),
							"trRecordId": item.id, //"入库明细ID",
							"fbaNo": null, //"FBA编号",
							"fbaPre": null, //"FBA PRE#"
						});
					});


					this.$set(this.form.recordList, blockData, index);
					this.$forceUpdate();

					// this.form.recordList = this.form.recordList.concat(new_recordList);

					this.drawer = false;
				});
			},
			//添加的选择的数据-库存的货物
			// addRecSkuInv() {
			// 	this.$nextTick(() => {
			// 		let selData = this.$refs.TransportSkuInventoriesListScroll.getSelData();
			// 		console.log("selData", selData);
			// 		if (selData.length <= 0) {
			// 			this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
			// 			return;
			// 		}
			// 		//新增
			// 		let HasRepeat = false; //是否存在重复
			// 		let new_recordList = [];
			// 		let old_recordList = this.form.recordList;
			// 		selData.map(item => {
			// 			let hasIndex = old_recordList.findIndex(item2 => {
			// 				return item2.ctnMark == item.goodsSku;
			// 			});
			// 			if (hasIndex > -1) { //存在
			// 				HasRepeat = true;
			// 			} else {
			// 				new_recordList.push({
			// 					"ctnMark": item.goodsSku, //this.$t('i18nn_fa4d50bc26e39b26'),
			// 					"inWhCode": '', //this.$t('i18nn_559fed47b0e95407'),
			// 					"inWhRecord": null, //"入库明细ID",
			// 					"trCtnCount": item.currentStock, //this.$t('i18nn_bdc361ba04506136'),
			// 					"trStockSour": this.form.trStockSour, //货物来源
			// 					"goodsSku": item.goodsSku,
			// 					"remark": null, //备注
			// 					// "remark":item.remark,//备注
			// 					// "trPallte": item.inWhPallte, //this.$t('i18nn_c8773d8d74202801')
			// 				});
			// 			}
			// 		});
			// 		if (HasRepeat) {
			// 			this.$message.success(this.$t('i18nn_a8bcab50b1eccec0'));
			// 		}
			// 		this.form.recordList = this.form.recordList.concat(new_recordList);

			// 		this.drawer = false;
			// 	});
			// },

			//新增块数据
			AddBlockData() {
				if (this.form.recordList.length >= 10) {
					this.$message.warning("不能超过10个批次");
					return;
				}
				this.form.recordList.push({
					"whNo": null, //this.$t('i18nn_a3b6f85b4a9530ff'),
					"addrType": null, //this.$t('i18nn_0bbce74881c893df'),
					"trAddrCode": null, //this.$t('i18nn_8758fd50c87d6c9c'),
					"trWay": null, //this.$t('i18nn_a29209bd396e4bdd'),
					"attachments": [], //this.$t('i18nn_f5d43732e3f6cf4d')
					pallteCount: 0,
					remark: "",
					"planList": [
						// {
						// 	"ctnMark": null, //this.$t('i18nn_73fc38e67381ae73'),
						// 	"quantity": null, //this.$t('i18nn_f9db93b87e08763b'),
						// 	"trRecordId": null, //"入库明细ID",
						// 	"fbaNo": null, //"FBA编号",
						// 	"fbaPre": null, //"FBA PRE#"
						// },
					]
				});
			},
			DelBlockData(event, index) {
				event.stopPropagation();
				this.form.recordList.splice(index, 1);
			},
			//新增
			addDet(index) {
				this.TransportOpenIndex = index;
				this.openDrawer(index);

				// let blockData = this.form.recordList[index];
				// blockData.planList.splice(index2, 1);
				// this.$set(this.form.recordList, blockData, index);
				// this.$forceUpdate();
			},
			// 删除
			delDet(event, index, index2) {
				event.stopPropagation();
				let blockData = this.form.recordList[index];
				if(blockData.planList && blockData.planList[index2] && blockData.planList[index2].id){//后端删除
					// this.delDetById(blockData.planList[index2].id);
					let id = blockData.planList[index2].id;
					this.$confirm('确定删除该条吗？', this.$t('tips.tipsTitle'), {
							type: 'warning'
						})
						.then(() => {
							this.postData(this.$urlConfig.WhTransferPlanOutWhDetDel, {
								"id": id
							}, () => {
								this.$message.success(this.$t('i18nn_de017dafc266aa03'));
								//前端删除
								blockData.planList.splice(index2, 1);
							});
						})
						.catch(() => {});
				} else {//前端删除
					blockData.planList.splice(index2, 1);
				}
				this.$set(this.form.recordList, blockData, index);
				this.$forceUpdate();
			},
			//清空地址
			clearAddrCode(v, index) {
				let blockData = this.form.recordList[index];
				blockData.trAddrCode = "";
				blockData.trAddrInfo = "";
				this.$set(this.form.recordList, blockData, index);
				this.$forceUpdate();
			},
			//打开地址选择
			openSelAddr(index) {
				this.TransportOpenIndex = index;
				this.TransportAddressOpenTime = new Date().getTime();
			},
			addrSelSuccess(data) {
				let index = this.TransportOpenIndex;
				let blockData = this.form.recordList[index];
				blockData.trAddrCode = data.code;
				blockData.trAddrInfo = [data.address, data.city, data.state, data.country, data.zipCode].join(',');
				this.$set(this.form.recordList, blockData, index);
				this.$forceUpdate();
			},

			//添加附件
			openAddFile(index) {
				this.TransportOpenIndex = index;
				this.FileUploadOpenTime = new Date().getTime();
			},

			//删除附件
			delFile(event, index, index2) {
				event.stopPropagation();
				// this.form.qaProgress.attachments.splice(index, 1);
				// this.$forceUpdate();

				// let index = this.TransportOpenIndex;
				let blockData = this.form.recordList[index];
				blockData.attachments.splice(index2, 1);
				this.$set(this.form.recordList, blockData, index);
				this.$forceUpdate();

			},

			//附件上传成功回调
			FileUploadSuccess(data) {
				console.log('FileUploadSuccess', data);
				let fileList = data.map(item => {
					return {
						fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
						url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
						type: "90"
					}
				});
				// // this.form.qaProgress.attachments = fileList;
				// this.form.qaProgress.attachments = this.form.qaProgress.attachments.concat(fileList);
				// this.$forceUpdate();

				let index = this.TransportOpenIndex;
				let blockData = this.form.recordList[index];
				blockData.attachments = blockData.attachments.concat(fileList);
				// blockData.trAddrInfo = [data.address, data.city, data.state, data.country, data.zipCode].join(',');
				this.$set(this.form.recordList, blockData, index);
				this.$forceUpdate();

			},
			//后端删除
			delFileAction(event,row,index, index2) {
				event.stopPropagation();
			  this.loadingUploadFile = true;
			  this.$http
			    .delete(this.$urlConfig.WhFileUploadDel + '/' + row.id, {})
			    .then(({ data }) => {
			      this.loadingUploadFile = false;
			      if (200 == data.code) {
							// this.form.attachments.splice(index, 1);
							
							let blockData = this.form.recordList[index];
							blockData.attachments.splice(index2, 1);
							this.$set(this.form.recordList, blockData, index);
							this.$forceUpdate();
							
							// this.$forceUpdate();
							this.$message.success(this.$t('i18nn_de017dafc266aa03'));
			      } else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_54dc58d9d0377bb5'));
			      }
			    })
			    .catch(error => {
			      console.log(error);
						this.loadingUploadFile = false;
						this.$message.warning(data.msg ? data.msg : '删除失败!');
			    });
			},
			

			// parsingSuccess(data){
			// 	this.formBase.sendDto = data;
			// },
			// changeAgentUser(val){
			// 	// this.filterData.agentUser = val;
			// 	// this.initData();
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//提交(先提交附件，回调之后提交新增或者编辑方法)
			submitFormAction() {
				this.$refs['form'].validate(valid => {
					if (valid) {
						// this.$nextTick(() => {
						// 	this.$refs.whFileUploadInner.submitExcelFormAction();
						// });
						this.submitData();
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//打开附件上传
			// openUploadFile() {
			// 	// event.stopPropagation();
			// 	// this.isShowFileUpload = true;
			// 	this.fileRelationId = this.editId;
			// 	this.FileUploadOpenTime = new Date().getTime();
			// },
			// //附件上传成功回调
			// FileUploadSuccess(data) {
			// 	console.log("FileUploadSuccess", data);
			// 	// this.initData();
			// 	this.form.fileList = data;

			// 	// return;
			// 	this.submitForm('form');
			// 	this.$forceUpdate();
			// 	// event.stopPropagation();
			// 	// this.isShowFileUpload = true;
			// 	// this.fileRelationId = row.id;
			// 	// this.FileUploadOpenTime = new Date().getTime();
			// },
			//删除明细-单条
			// delDetById(id) {
			// 	this.$confirm('确定删除该条吗？', this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhTransferPlanOutWhDetDel, {
			// 				"id": id
			// 			}, () => {
			// 				this.$message.success(this.$t('i18nn_de017dafc266aa03'));
			// 			});
			// 		})
			// 		.catch(() => {});
			// },
			//查询FBA地址
			// searchFbaAddress() {
			// 	console.log('searchFbaAddress');
			// 	if ('' == this.form.fbaAddrCode) {
			// 		this.$message.warning("请输入FBA仓库号");
			// 		return;
			// 	}
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhTransferQueryFba + '?fbaCode=' + this.form.fbaAddrCode, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_776496ec446f21f9'));
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code && data.data) {
			// 				// "country": null, //this.$t('i18nn_b166e4e8fe9513fa'),
			// 				// "state": null, //"省/州",
			// 				// "city": null, //this.$t('i18nn_e05b5d049b64b040'),
			// 				// "postalCode": null, //this.$t('i18nn_c4913ab43009b365'),
			// 				// "addr": null, //this.$t('i18nn_8758fd50c87d6c9c'),
			// 				// "phone": null, //this.$t('i18nn_c2d9cc88561f8cdc'),
			// 				let addrData = data.data;
			// 				this.form.country = addrData.country;
			// 				this.form.state = addrData.state;
			// 				this.form.city = addrData.city;
			// 				this.form.postalCode = addrData.zipCode;
			// 				this.form.addr = addrData.address;
			// 				this.form.phone = addrData.phone;

			// 				// this.fbaAddress = data.data;

			// 			} else {
			// 				// if (!data.msg) {
			// 				// 	data.msg = this.$t('i18nn_d74d0bd89431d6d5');
			// 				// }
			// 				// this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.errorData'));
			// 			this.loading = false;
			// 			// this.$message.error('查询失败!');
			// 		});
			// },

			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			// 	// console.log(formParm);
			// 	this.dialogFormVisible = true;
			// 	let form = Object.assign({}, formParm);
			// 	console.log('form', form);
			// 	this.dialogFormMsg = msg;
			// 	// // 重置
			// 	this.resetForm('form');
			// 	if (null === formParm) {
			// 		//新增

			// 		// this.form.weightUnit = '1';
			// 		// this.form.volumeUnit = '1';
			// 		// this.form.whFeeType = '1';

			// 		this.dialogFormStatus = 0;
			// 		// form.subUserId = null;
			// 		// form.userSubUserId = null;
			// 		// form.state = true;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	} else {
			// 		//修改
			// 		this.dialogFormStatus = 1;
			// 		// form.state = form.state === '0' ? true : false;

			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	}

			// 	this.$forceUpdate();
			// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			// 	//       type: 'warning',
			// 	//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	//     });
			// },
			// 富文本
			// onEditorChange(html) {
			// 	this.form.contentStr = html;
			// },
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// 	this.getPageDetData(row.id);
			// },
			//请求分页数据
			getPageBaseData(id) {
				// let _this = this;
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferPlanOutWhQuery + '/' + id, {})
					.then(({
						data
					}) => {
						console.log('分页，请求成功');
						console.log(data);
						this.loading = false;
						this.form.recordList = [data.data];
						// if (this.form.recordList && this.form.recordList[0]) {
						// 	this.form.trStockSour = this.form.recordList[0].trStockSour;
						// }

						// this.getPageDetData(this.editId);
						// this.$nextTick(() => {
						// 	this.$refs.whNoSelect.init(this.form.whNo);
						// 	this.initTransportInventory();
						// });
						// if (0 != data.data.status && 10 != data.data.status) {
						// 	this.isOnlyView = true;
						// }

						// this.isSaveAddress = this.form.addr ? true : false;
						// this.isShowSkuCheck = this.form.isShowSku == 1 ? true : false;
						// this.form.isShowSku = this.form.isShowSku==1?true:false;
						// this.$nextTick(() => {
						// console.log('this.form.recordList', this.form.recordList);
						// this.$refs.HotTableTransport.loadData(this.form.recordList);
						// });
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			},
			//明细
			//请求分页数据
			// getPageDetData(id) {
			// 	// let _this = this;
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhTransferDetQuery + '/' + id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			this.loading = false;
			// 			// this.form = data.data;
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading = false;
			// 		});
			// },
			//打开选择数据
			// openSelData(event, row, index) {
			// 	event.stopPropagation();

			// 	this.dialogSelVisible = true;
			// 	this.openRowData = row;
			// 	this.openRowIndex = index;
			//  // this.$nextTick(() => {
			// 	//   this.$refs.hyUpLoadImg1.openFile();
			// 	// });
			// },
			// //选择数据后回调
			// selWhGoodsData(selRow) {
			// 	this.dialogSelVisible = false;
			// 	console.log('selRow', selRow);
			// 	let row = this.openRowData;
			// 	// row.putWhNo = selRow.putWhNo;
			// 	// row.plPlaceNo = selRow.placeNo;
			// 	row.goodsSku = selRow.goodsSku;
			// 	// row.sendAcount = 0;
			// 	// row.mySkuDto = selRow;

			// 	this.$set(this.form.recordList, this.openRowIndex, row);
			// },
			//查看图片
			// openBigImg(event, imgsrc) {
			// 	event.stopPropagation();
			// 	console.log('openBigImg', imgsrc);
			// 	this.dialogImgVisible = true;
			// 	this.imgUrlBigShow = imgsrc;
			// },
			// fileChange(e) {
			// 	// this.loading = true;
			// 	console.log(e);

			// 	console.log(this.$t('i18nn_f156a9536049f461'), this.$refs.file);
			// 	// this.excelData = [];
			// 	this.form.sendRecordDtoList = [];
			// 	(this.fileExcel = null), (this.excelFileName = '');
			// 	if (!this.$refs.file || !this.$refs.file.files) {
			// 		this.$alert(this.$t('module.upload.Unknown_file'), this.$t('tips.tipsTitle'), {
			// 			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 		});
			// 		return;
			// 	}
			// 	if (this.$refs.file.files[0]) {
			// 		// if (this.fileAccept.indexOf(this.$refs.file.files[0].type) > -1) {
			// 		if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
			// 			this.fileExcel = this.$refs.file.files[0];
			// 			this.excelFileName = this.fileExcel.name;
			// 			// console.log(this.$t('i18nn_f156a9536049f461'),this.$refs.file);
			// 			// this.uploadExcel();
			// 		} else {
			// 			this.$alert(this.$t('module.upload.uploaded_over') + '10M', this.$t('tips.tipsTitle'), {});
			// 		}
			// 		// } else {
			// 		//   this.$alert(this.$t(this.$t('i18nn_4dadf13ebce2167d')), this.$t('tips.tipsTitle'), {
			// 		//   });
			// 		// }
			// 	} else {
			// 		// this.$alert(this.$t('i18nn_c8e99e36cefd743a'), this.$t('tips.tipsTitle'), {
			// 		// });
			// 		console.log(this.$t('i18nn_c8e99e36cefd743a'), this.$refs.file);
			// 	}
			// },
			// clearFile(){
			// 	this.fileExcel = null;
			// 	this.excelFileName = '';
			// 	// this.excelFileName = "";
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.file.value = '';
			// 		} catch (e) {
			// 			console.log(e);
			// 			//TODO handle the exception
			// 		}
			// 	});
			// },
			// openExcel() {
			// 	try {
			// 		this.$refs.file.value = '';
			// 	} catch (e) {
			// 		console.log(e);
			// 		//TODO handle the exception
			// 	}
			// 	// this.$refs.file.value = '';
			// 	// this.$refs.file.dispatchEvent(new MouseEvent('click'));
			// 	var comment = this.$refs.file;
			// 	if (document.all) {
			// 		// For IE
			// 		comment.click();
			// 	} else if (document.createEvent) {
			// 		// 在ff中要为a标签添加click事件，并且侦听该事件
			// 		var ev = document.createEvent('MouseEvents');
			// 		ev.initEvent('click', false, true);
			// 		comment.dispatchEvent(ev);
			// 	} else {
			// 		// this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
			// 		this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
			// 			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 		});
			// 	}
			// },
			//导入excel
			// uploadExcel() {
			// 	if (!this.fileExcel) {
			// 		this.$alert(this.$t('module.upload.Please_sel_file'), this.$t('tips.tipsTitle'), {});
			// 		return;
			// 	}

			// 	this.loadingExcel = true;

			// 	let file = this.fileExcel;
			// 	var formdata = new FormData();
			// 	formdata.append('file', file);

			// 	this.$http
			// 		.post(this.$urlConfig.WhShipmentsListExUpload, formdata, {
			// 			headers: {
			// 				'Content-Type': 'multipart/form-data'
			// 			}
			// 		})
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			this.loadingExcel = false;
			// 			if (200 == data.code && data.rows) {
			// 				// this.excelData = data.rows;

			// 				this.form.sendRecordDtoList = data.rows;

			// 				this.$forceUpdate();
			// 				this.clearFile();
			// 				// if('1'==data.data.flag){
			// 				//   this.excelData = data.data.data;
			// 				// } else {
			// 				//   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			// 				//     type: 'warning',
			// 				//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 				//   });
			// 				// }
			// 			} else {
			// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 					//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 				});
			// 			}
			// 			// if (200 == data.code && data.data) {
			// 			//   if('1'==data.data.flag){
			// 			//     this.excelData = data.data.data;
			// 			//   } else {
			// 			//     this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			// 			//       type: 'warning',
			// 			//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			//     });
			// 			//   }

			// 			// } else {
			// 			//   this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			// 			//     type: 'warning',
			// 			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			//   });
			// 			// }
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loadingExcel = false;
			// 			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			});
			// 		});
			// },

			//上传回调
			// changeFileUrl(val) {
			// 	console.log('changeFileUrl', val);
			// 	let listUrl = val.map(item => {
			// 		// "filePath":"","fileName"：“”
			// 		return {
			// 			url: item.url,
			// 			fileName: item.name
			// 		};
			// 	});

			// 	this.form.attachments = listUrl;
			// },
			submitData() {
				console.log('form', this.form);
				let formData = this.form.recordList;
				// if (fbaAddrCode) {
				// 	formData.fbaAddrCode = fbaAddrCode;
				// }
				console.log('formData', formData);
				if (this.editId) {
					let formData = this.form.recordList[0];
					console.log('formData', formData);
					// formData.id = this.editId;
					this.postData(this.$urlConfig.WhTransferPlanOutWhUpdate, formData, () => {
						console.log('submitSuccess');
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						// this.$emit('submitSuccess');
						this.goBack();
					});
				} else {

					let formData = this.form.recordList;
					console.log('formData', formData);
					this.postData(this.$urlConfig.WhTransferPlanOutWhAdd, formData, () => {
						console.log('submitSuccess');
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						// this.$emit('submitSuccess');
						this.goBack();
					});
				}
			},
			//提交信息
			// submitForm() {
			// 	//不是FBA,且地址名称为空
			// 	if ('0' == this.form.isFba && this.isSaveAddress) {
			// 		this.saveAddress();
			// 	} else {
			// 		this.submitData();
			// 	}
			// },

			// saveAddress() {
			// 	this.$nextTick(() => {
			// 		let id = null;
			// 		// if(this.$refs.transferAddressSel){
			// 		id = this.$refs.transferAddressSel.getId()
			// 		// }
			// 		let address_formData = {
			// 			"id": id ? id : null, //"数据ID",
			// 			"name": this.addressName, //this.$t('i18nn_59764ba919e3d70d'),
			// 			"address": this.form.addr, //this.$t('i18nn_8758fd50c87d6c9c'),
			// 			"city": this.form.city, //this.$t('i18nn_e05b5d049b64b040'),
			// 			"state": this.form.state, //this.$t('i18nn_7cab79716236ebb0'),
			// 			"zipCode": this.form.postalCode, //this.$t('i18nn_c4913ab43009b365'),
			// 			"country": this.form.country, //"US"
			// 			// phone: this.form.phone, //"phone"
			// 		};
			// 		// if(this.$refs.transferAddressSel){
			// 		this.$refs.transferAddressSel.addData(address_formData);
			// 		// }
			// 	})
			// },
			//保存地址回调
			// saveToAddress(data) {
			// 	if (data && 200 == data.code && data.data && data.data.code) {
			// 		this.submitData(data.data.code);
			// 	} else {
			// 		this.submitData();
			// 	}
			// },
			//重置输入框
			// resetForm(formName) {
			// 	console.log(formName);
			// 	// console.log(this.$refs[formName]);

			// 	this.$nextTick(() => {
			// 		if (this.$refs[formName]) {
			// 			this.$refs[formName].resetFields();
			// 			// if ('form' === formName) {
			// 			// 	this[formName]['packRecordList'] = [];
			// 			// }
			// 		} else {
			// 			console.log('this.$refs[formName]', this.$refs[formName]);
			// 		}
			// 	});
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.HyElWhUpLoad.initData();
			// 			this.$refs.HyElWhUpLoad.clearFileList();
			// 		} catch (err) {
			// 			console.log(err);
			// 		}
			// 	});
			// 	this.fileList = [];
			// },

			//提交信息
			postData(url, formData, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				// if ('delete' == type) {
				// 	HttpType = this.$http.delete(url, formData);
				// } else {
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					this.loading = false;
					if (200 == data.code) {

						callback();
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

			//请求
			getWhListData() {
				this.$http
					.put(this.$urlConfig.WhCusBindWhList, {})
					.then(({
						data
					}) => {
						// console.log('查询数据字典，请求成功');
						console.log(data);
						if (200 == data.code && data.rows) {
							// this.list = data.rows;
							let list = data.rows;
							this.selectOption.whNo = list.map(item => {
								return {
									code: item.whNo,
									codeText: `${item.whName}(${item.address}.${item.city},${item.state} ${item.postalCode})`,
									codeTextEn: `${item.whNameEn}(${item.address}.${item.city},${item.state} ${item.postalCode})`
								}
							});
							// this.$emit('loadSuccess',this.list);
							// this.$emit('loadAllSuccess',list);
							// this.selectOption.wh_vol_unit = data.data["wh_vol_unit"];
						} else {
							if (!data.msg) {
								data.msg = this.$t('tips.errorData');
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						// console.log('查询数据字典接口，请求失败');
						this.$message.error(this.$t('tips.requestErrorData'));
					});
			},

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['biz_wh_tr_plan_way', 'biz_wh_tr_plan_addr_type','wh_car_tms_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.biz_wh_tr_plan_way = data.data['biz_wh_tr_plan_way'];
			// 				this.selectOption.biz_wh_tr_plan_addr_type = data.data['biz_wh_tr_plan_addr_type'];
			// 				this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.queryErrorFailed');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.queryRequestFailed'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.hover-highlight-red {
 	&:hover {
 		border-color:red;
 	}
 }
</style>